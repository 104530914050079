$color1: #6C7DFF;
$color2: #FFCDEC;
$color3: #FFFFFF;
$color4: #150847;

$bg: $color4;
$text: $color3;

$designRate: 0.06975;

//字号
$globalfont: 0.9765vw;//14px;
$h1: 5.58vw;//80px;
$h2: 4.464vw;//64px;
$h3: 2.79vw;//40px;
$subtitle1: 2.232vw;//32px;0.06975
$subtitle2: 1.674vw;//24px;
$navfont: 0.9765vw;//14px;
$textfont1: 1.395vw;//20px;
$textfont2: 0.9765vw;//14px;
$textfont3: 1.116vw;//16px;


// spacing
$globalspacing:11.50875vw;//165px
$spacing1: 10px;
$spacing2: 20px;
$spacing3: 30px;
$spacing4: 2.79vw;//40px;

$globalspacing-mb: 7vw;
