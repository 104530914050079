@import './../themes/variants';

.bg-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .gov-title {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate3d($globalspacing,28vh,0);
    z-index: 50;
    opacity: 0;
  }
}
.stage1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(./../assets/bg/stage1.png) no-repeat;
  background-size: cover;
  background-color: $color4;
  z-index: 10;
}
.stage2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  background: linear-gradient(0deg, $color2 -83.19%, $color4 55.63%);
}
.stage3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: linear-gradient(0deg, #FFCDEC -83.19%, #150847 100%);
  
}
.eco-balls {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(./../assets/bg/stage3.png) no-repeat;
  background-size: cover;
}
.circle {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(640vw * $designRate,57vh,0);
  width: 1120vw * $designRate;
  height: 1120vw * $designRate;
  z-index: 50;
  background: url(./../assets/bg/circle2.png) no-repeat;
  background-size: 100% 100%;
  opacity: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;

  .gov-circle {
    opacity: 0;
    width: 60%;
    height: 60%;
    background: url(./../assets/bg/gov-circle.png) no-repeat;
    background-size: 100% 100%;
  }
}
.eco-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 100vw;
    height: 100vw;
    background: url(./../assets/eco-bg.png) no-repeat;
    background-size: 100vw auto;
    background-position: center center;
    border-radius: 50%;
    opacity: 0;
    // transform: scale3d(0.1, 0.1, 0.1);
  }
}
.roadmap-balls {
  position: absolute;
  top: 120vh;
  left: 0;
}