
.ball {
  position: absolute;
  left: 0;
  top: 0;
  background: url(./../assets/bg/ball.png);
  mix-blend-mode: screen;
  border-radius: 106px;
  background-size: 100% 100%;
  &.blur {
    // filter: blur(3px);
  }
}