@import './../themes/variants';

// join page
.join-page {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  opacity: 1!important;
  h1 {
    margin-bottom: 5vh;
  }

  button,.button {
    margin: 0 1vw;
  }
}
.join-ball {
  transform: translate3d(0,120vh,0);
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(./../assets/bg/end.png) no-repeat;
  background-size: 1000vw * $designRate auto;
  background-position: center bottom;
  // z-index: 5;
}
.join-content {
  position: relative;
  transform: translate3d(0,50vh,0);
  opacity: 0;
  z-index: 5;
}
.contact-us {
  opacity: 0;
  position: absolute;
  bottom: 10vh;
  left: 0;
  right: 0;
  text-align: center;
  color: $color4;
  > div:nth-child(1) {
    margin-bottom: 2vh;
  }
  > div:nth-child(2) {
    a {
      margin: 0 12vw*$designRate 0;
    }
    
  }
}

@media only screen and (max-width: 1024px) {
  .join-page {
    background: url(./../assets/mobile/bg5.jpg) no-repeat;
    background-position: center bottom;
    background-size: cover;
    display: block;
    position: relative!important;
  }
  .join-content {
    transform: translate3d(0,28vh,0);
    opacity: 1;
    h1 {
      width: 70vw;
      margin: 0 auto 5vh;
      font-size: 12vw;

    }
    > div {
      width: 50%;
      margin: 0 auto;
      .button, button {
        padding: 0 10vw;
        margin-bottom: 2.5vh;
      }
    }
  }
  .contact-us {
    // position: static;
    opacity: 1;
    bottom: 13vh;
    .textfont2 {
      font-size: 4vw;
      margin-bottom: 2vh;
    }
    > div:nth-child(2) {
      display: flex;
      width: 70%;
      margin: 0 auto;
      justify-content: space-around;
    }
  }
  .join-ball {
    position: static;
    display: none;
  }
}