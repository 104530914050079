@import 'variants';
html {
  height: 100%;
  overflow: hidden;
  
}

body,html {
  max-height: 100%;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  scrollbar-width: none; /* Firefox */

}
h1,h2,h3,h4,h5 {
  margin: 0;
}
body {
  overflow-x: hidden;
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: url(./../assets/full.jpg) no-repeat top center;//$bg;
  // background-size: 100% auto;
  //background-color: $bg;
  background: $bg;
  color: $text;
  font-size: $globalfont;

}

code {
  font-family: 'Poppins', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $text;
  &:hover {
    color: $color1;
  }
}
span.atag {
  cursor: pointer;
  color: $text;
  &:hover {
    color: $color1;
  }
}

button, .button {
  position: relative;
  display: inline-block;
  font-weight: 600;
  line-height: 41px;
  border-radius: 41px;
  padding: 0 20px;
  font-size: inherit;
  color: $text;
  cursor: pointer;
  -webkit-appearance: none;
  &.primary {
    border: 1px solid $color1;
    background: $color1;
  
    &:hover {
      border: 1px solid $color2;
      background: $color2;
      color: $color4;
    }
  }
  &.secondary {
    border: 1px solid $color3;
    background: none;
  
    &:hover {
      color: $color1;
      border-color: $color1;
    }
  }
  .bartag {
    display: none;
    position: absolute;
    right: -3vw;
    top: -1vw;;
    background: $color1;
    color: $color3;
    z-index: 5;
  }
  &:hover {
    .bartag {
      display: block;
    }
  }
}

h1 {
  font-size: $h1;
}
h2 {
  font-size: $h2;
}
h3 {
  font-size: $h3;
}
h4 {
  font-size: $subtitle1;
  font-weight: 700;
}
h5 {
  font-size: $subtitle2;
}
.color1 {
  color: $color1;
}
.color2 {
  color: $color2;
}
.color3 {
  color: $color3;
}
.color4 {
  color: $color4;
}
.textfont1 {
  font-size: $textfont1;
}
.textfont2 {
  font-size: $textfont2;
}

.hide {
  display: none!important;
}

.panel {
  position: relative;
  border: 3px solid rgba(255,255,255,0.1);
  background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.06) 28.69%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(100px);
  border-radius: 24px;
}

.panel-close {
  position: absolute;
  right: 18vw * $designRate;
  top: 15vw * $designRate;
  background: url(./../assets/icons/close.png);
  background-size: 100% auto;
  width: 24vw * $designRate;
  height: 24vw * $designRate;
  cursor: pointer;
}


@media only screen and (max-width: 1024px) {
  body,html {
    height: auto;
  }
  h2 {
    font-size: 9vw;
  }
  h3 {
    font-size: 5vw;
  }
  .textfont1 {
    font-size: 3.8vw;
  }
  button, .button {
    font-size: 3vw;
  }
}