@import './../themes/variants';

.header {
  position: fixed;
  left: $globalspacing;
  top: 0;
  right: $globalspacing;
  z-index: 500;
}
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-top: 7.2vh;
  font-size: $navfont;
}
.logo {
  height: 24px;
  img {
    height: 100%;
  }
}
.nav {
  .atag {
    margin-right: $spacing4;
    font-weight: 700;
    svg {
      margin-left: 4px;
    }
  }  
}
@media only screen and (max-width: 1024px) {
  .header {
    left: $globalspacing-mb;
    right: 60vw;
  }
  .header-inner {
    padding-top: 5vh;
  }
  .mobile-menu {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color4;
    z-index: 20;
  }
  .menu-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding-top: 5vh;
    padding-right: $globalspacing-mb;
    padding-left: $globalspacing-mb;
    padding-bottom: 1vh;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .menu-panel {
    position: absolute;
    top: 20vh;
    left: $globalspacing-mb;
    bottom: 30vh;
    right: $globalspacing-mb;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 7vw;
    padding-right: 7vw;
    // background: red;

    button, .button {
      width: 35%;
      text-align: center;
      font-size: 4vw;
    }
  }
  .menu-link {
    font-size: 7vw;
    font-weight: bold;

    svg {
      margin-left: 4vw;
      width: 4vw;
      height: 4vw;
    }
  }
  .menu-contactus {
    position: absolute;
    bottom: 8vh;
    left: $globalspacing-mb;
    right: $globalspacing-mb;
    padding-left: 7vw;
    padding-right: 7vw;
    .textfont2 {
      font-size: 4vw;;
    }
    > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      margin-top: 3vh;
    }
  }
  .hide {
    display: none;
  }
}