@import './../themes/variants';

// feature-page
.feature-page {
  .content{
    display: flex;
    margin-left: $globalspacing;
    margin-right: $globalspacing;
    justify-content: space-between;
    height: 100%;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      text-align: left;
      h2 {
        margin-bottom: 3vh;
      }
      .textfont1 {
        line-height: 1.5;
        margin-bottom: 3vh;
      }
    }
    .right {
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      text-align: left;
      font-weight: 700;
      margin-bottom: 18vh;
      .top {
        line-height: 1.5;
        width: 340vw * $designRate;
        height: 210vw * $designRate;
        overflow: hidden;
        img {
          width: 8.37vw;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        .feature-slider-wrapper {
          height: 100%;
          display: flex;
        }
        .feature-slider-item {
          position: relative;
          width: 340vw * $designRate;
          
        }
      }
      
      .bot {
        position: relative;
        top: -1.5vw;
        letter-spacing: 1.5em;
        z-index: 2;
        span {
          opacity: 0.5;
          &:hover, &.active {
            color: $color3;
            opacity: 1;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .feature-page {
    background: url(./../assets/mobile/bg2.jpg) no-repeat;
    background-size: cover;
    .content{
      flex-direction: column;
      justify-content: space-between;
      margin-left: $globalspacing-mb;
      margin-right: $globalspacing-mb;
      .left {
        justify-content:flex-start;
        h2 {
          padding-top: 12vh;
        }
        .textfont1 {
          margin-bottom: 5vh;
        }
      }
      .right {
        width: 100%;
        // margin-bottom: 8vh;
        padding-top: 18vh;
        padding-left: 9vw;
        padding-right: 9vw;
        box-sizing: border-box;
        .top {
          width: 100%;
          height: 27vh;
          .feature-slider-item {
            padding-top: 15vh;
            font-weight: normal;

            img {
              position: absolute;
              left: 0;
              top: 0;
              width: 12vh;

            }
          }
        }
        .bot {
          font-size: 5vw;
          letter-spacing: 0;
          span {
            text-align: center;
            padding: 0 4vw;
          }
        }
      }
    }
  }
}