@import './../themes/variants';

// countdown page
.countdown-page {
  &.style-online {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    .content-text {
      padding-top: 0;
      padding-left: $globalspacing;
      text-align: left;
    }
    h1 {
      margin-top: 2vh;
      margin-bottom: 5vh;
    }
  }
  h3 {
    margin: 0 auto;
    width: 70vw;
    letter-spacing: 0.05em;
    padding-bottom: 3vh;
  }
  .content-text {
    // padding-left: $globalspacing;
    // padding-right: $globalspacing;
    padding-top: 25vh;
    .text-child2 {
      letter-spacing: 0.05em;
      margin-top: 5vh;
      margin-bottom: 5vh;
    }
  }
  .bottom {
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 8vh;
    opacity: 0.5;
    text-align: center;
    letter-spacing: 0.76em;
    font-weight: 600;
    font-size: $textfont3;
  }
  .time-wrapper {
    position: absolute;
    top: 40vh;
    left: 0;
    right: 0;
  }
  .time {
    display: inline-block;
    margin-bottom: 5vh;
  }
  .time-top {
    font-size: 8.37vw;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: 1.5vh;
    > span {
      display: inline-flex;
      width: 167vw * $designRate;
      flex-direction: column;
      margin: 0 35vw * $designRate;
      span {
        font-size: 1.2555vw;
        opacity: 0.5;
        letter-spacing: 0.2em;
        font-weight: normal;
        color: #fff;
        
      }
    }
    > span:first-child {
      width: auto;
    }
  }
  // .time-bot {
  //   display: flex;
  //   justify-content: space-between;
  //   font-size: 1.2555vw;
  //   opacity: 0.5;
  //   letter-spacing: 0.2em;
  //   span {
  //     width: 30%;
  //   }
  // }
}
@media only screen and (max-width: 1024px) {
  .countdown-page {
    &.style-online {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      .content-text {
        text-align: center;
        padding: 0;
        
      }
      h1 {
        margin-top: 2vh;
        margin-bottom: 5vh;
        span {
          font-size: 6vw;
        }
      }
      h4 {
        font-size: 4.8vw;
        
      }
    }


    background: url(./../assets/mobile/bg1.png) no-repeat;
    // background-position: ;
    background-size: cover;
    .content-text {
      padding-left: $globalspacing-mb;
      padding-right: $globalspacing-mb;
      .text-child2 {
        margin-top: 5vh;
        line-height: 1.8;
      }
      h3 {
        font-size: 6vw;
      }
    }
    h1 {
      margin: 0 auto;
      max-width: 90vw;
      line-height: 2;
    }
    .time-top {
      font-size: 12vw;

      > span {
        width: 17vw;
      }
    }

    .arrow {
      position: absolute;
      bottom: 12vh;
      left: 0;
      right: 0;
      // width: 

    }
    .bottom {
      display: none;
    }
  }
}