@import './../themes/variants';

.investers-page {
  .content {
    padding-left: $globalspacing;
    padding-right: $globalspacing;
  }
  h4 {
    text-align: left;
    padding-top: 21vh;
  }
  .brands {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    margin-top: 5vh;
  }
  .img {
    width: 90%;
    max-width: 180px;
    height: 55px;
    object-fit: contain;
    border: 1px solid white;
    padding: 4px 10px;
    box-sizing: border-box;
    margin-bottom: 30px;
    filter: brightness(10);
    transition: 0.2s;
    &:hover {
      border-color: rgba(white, 0.1);
      filter: brightness(1);
    }
  }
  @media (max-width: 1024px) {
    background: url(./../assets/mobile/bg4.jpg) no-repeat;
    background-size: cover;
    .content {
      padding-left: $globalspacing-mb;
      padding-right: $globalspacing-mb;
      padding-top: 12vh;
    }
    h4 {
      font-size: 9vw;
      padding-top: 0;
    }
    .brands {
      grid-template-columns: 33% 33% 33%;
    }
    .img {
      margin-bottom: 15px;
    }
  }
}
