@import './themes/variants';
.App {
  text-align: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  // scroll-snap-align: start;
}

// -------------------------------------
.common-page {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 60;
  width: 100%;
  height: 100vh;
}

.placeholder_box {
  height: 100vh;
  scroll-snap-align: start;
  z-index: -1;
}


@media only screen and (max-width: 1024px) {
  .App {
    overflow: initial;
  }
  .common-page {
    position: static;
    opacity: 1;
    width: 100%;
    // height: auto;
  }
  
}