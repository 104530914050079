@import './../themes/variants';

//roadmap
.roadmap-page {
  .content {
    height: 100%;
    margin-left: $globalspacing;
    margin-right: $globalspacing;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right-grid {
      display: grid;
      grid-template-columns: repeat(2, 20vw);
      grid-template-rows: repeat(2, 25vh);
      padding-right: 5vw;

      .item-wrapper2 {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-bottom: 3vh;
      }
      .item-wrapper3 {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding-top: 1vh;
        padding-right: 3vw;
        
      }
      .item-wrapper4 {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 1vw;
      }
    }
    .right-grid-item {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        .plus-btn {
          box-shadow: 0px 0px 20px $color1;
        }
      }
      .textfont1 {
        font-weight: 700;
        margin-top: 2vh;
        margin-bottom: 1vh;
      }
    }
  }

  .plus-btn {
    display: flex;
    width: 1.953vw;
    height: 1.953vw;
    background: $color1;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      width: 70%;
    }

    // &:hover {
    //   box-shadow: 0px 0px 20px $color1;
    // }
  }
 
  .mode2 {
    color: #fff;
  }
  .roadmap-panel {
    display: flex;
    padding: 40vw * $designRate 64vw * $designRate;
    width: 625vw * $designRate;
    box-sizing: border-box;
    
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 40vw * $designRate;
      .btn {
        width: 39vw * $designRate;
        height: 39vw * $designRate;
        border: 1px solid $text;
        color: $text;
        border-radius: 50%;
        font-size: 14vw * $designRate;
        text-align: center;
        line-height: 40vw * $designRate;
        cursor: pointer;
        &.active {
          background: $color1!important;
          border: 1px solid $color1!important;
          color: $text!important;
        }
        &:hover {
          background: none;
          border: 1px solid $color1;
          color: $color1;
        }
      }
      .line {
        width: 1px;
        height: 40vw * $designRate;
        background: $text;
      }
    }
    .right {
      padding-right: 10vw * $designRate;
      .textfont1{
        font-weight: 700;
        display: flex;
        .color2 {
          margin-right: 7px;
        }
        .bartag {
          margin-left: 7px;
          font-weight: normal;
        }
      }
      ul {
        line-height: 1.8;
        padding-left: 20vw*$designRate;
      }
    }
  }
}
.bartag {
  display: inline-block;
  color: $color2;
  background: rgba(255,255,255,0.1);
  width: 6.905vw;
  height: 1.674vw;
  line-height: 1.674vw;
  border-radius: 1.674vw;
  text-align: center;
  font-size: 0.837vw;
}
@media only screen and (max-width: 1024px) {
  .roadmap-page {
    background: url(./../assets/mobile/bg4.jpg) no-repeat;
    background-size: cover;
    overflow: hidden;
    .content {
      display: block;
      margin-left: $globalspacing-mb;
      margin-right: $globalspacing-mb;
      padding-top: 12vh;
    }
    h2 {
      margin-bottom: 3vh;
    }
    // .rmp-title {

    // }
    .rmp-title {
      display: flex;
      font-weight: 700;
      font-size: 5.5vw;
      justify-content: space-between;
      > span {
        width: 8vw;
        height: 8vw;
      }
    }
    .roadmap-mb-panel {
      border: 3px solid rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      padding: 5vw;
      box-sizing: border-box;
      background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.06) 28.69%, rgba(255, 255, 255, 0) 100%);
      backdrop-filter: blur(100px);
      margin-bottom: 1vh;

      .plus-btn {
        svg:nth-child(2) {
          display: none;
        }
      }
      .rmp-bot {
        display: none;
      }
      &.open {
        .plus-btn {
          svg:nth-child(1) {
            display: none;
          }
          svg:nth-child(2) {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .rmp-bot {
          display: block;
          font-size: 3.5vw;
          line-height: 1.4;
          ul {
            padding-left: 5.5vw;
            margin-bottom: 0;
            margin-top: 2vh;
          }
        }
        
      }
    }
    
  }
  .bartag {
    width: 25vw;
    height: 6vw;
    margin-bottom: 1.5vh;
    line-height: 6vw;
    font-size: 3.5vw;
    border-radius: 6vw;
  }
}