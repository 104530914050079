@import './../themes/variants';

// .governance-page
.governance-page {
  .content {
    display: flex;
    margin-left: $globalspacing;
    margin-right: $globalspacing;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .left {
      // display: flex;
      position: relative;
      text-align: left;
      flex-direction: column;
      // justify-content: center;
      height: 100%;
      top: 28vh;

      h2 {
        margin-bottom: 3vh;
        visibility: hidden;
      }
      .textfont1 {
        margin-bottom: 4vh;
        letter-spacing: 1px;
      }
      .textfont2 {
        width: 50vh;
        line-height: 1.5;
        ul {
          padding-left: 1.5em;
        }
        li {
          margin-bottom: 1em;
        }
      }
    }
    .right {
      position: absolute;
      left: 720vw * $designRate;
      top: 24vh;
      width: 551vw * $designRate;
      height: 484vw * $designRate;
      background: url(./../assets/gov-1.png);
      background-size: 100% 100%;
    }
  }
}

// governance-page2,3
.governance-page2 {
  transform: translate3d(0,50vh,0);
  text-align: left;
  .textfont2 {
    font-weight: 700;
    visibility: hidden;
  }
  h4 {
    margin-bottom: 7vh;
  }
  .content {
    margin-left: $globalspacing;
    margin-right: $globalspacing;
    padding-top: 20vh;
  }
  .content-img {//0.06975
    width: 1110vw * $designRate;
    height: 341vw * $designRate;
    background: url(./../assets/gov-2.png);
    background-size: 100% 100%;
  }
  height: auto;
}

.governance-page3 {
  transform: translate3d(0,50vh,0);
  text-align: left;
  .textfont2 {
    font-weight: 700;
  }
  h4 {
    margin-bottom: 7vh;
  }
  .content {
    margin-left: $globalspacing;
    margin-right: $globalspacing;
    padding-top: 20vh;
  }
  .content-text {
    display: flex;
    img {
      width: 100%;
    }
    // background: url(./assets/gov-3-bg2.png) no-repeat;
    // background-size: 100% auto;
    > div {
      width: 25%;
      // flex-grow: 1;
      // margin-right: 30vw * $designRate;
    }
    > div:nth-child(3){
      width: 50%;
      // flex-basis: 25%;
      // margin-right: 0;
    }
  }
}


@media only screen and (max-width: 1024px) {
  .governance-page {
    background: url(./../assets/mobile/bg3.png) no-repeat;
    background-size: cover;
    .content {
      display: block;
      margin-left: $globalspacing-mb;
      margin-right: $globalspacing-mb;
      .left {
        top: 12vh;
        h2 {
          visibility: visible;
          margin-bottom: 4vh;
        }
        img {
          width: 100%;
          margin-bottom: 6vh;
        }
        .textfont1 {
          font-size: 6vw;
          line-height: 1.5;
          margin-bottom: 2vh;
        }
        .textfont2 {
          width: auto;
          font-size: 3.5vw;
          li {
            margin-bottom: 1.3vh;
          }
        }
      }
      

      .right {
        display: none;
      }
    }
  }

  .governance-page2 {
    background: url(./../assets/mobile/bg3.png) no-repeat;
    background-position: center top;
    background-size: cover;
    transform: translate3d(0,0,0);
    height: auto!important;
    padding-bottom: 1vh;
    .content {
      margin-left: $globalspacing-mb;
      margin-right: $globalspacing-mb;
      padding-top: 12vh;
      .textfont2 {
        visibility: visible;
        font-size: 3.5vw;
      }
      h4 {
        font-size: 9vw;
        margin-bottom: 3vh;
      }
      .content-img {
        width: 100%;
        height: 30vw;
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
  }

  .governance-page3 {
    transform: translate3d(0,0,0);
    background: url(./../assets/mobile/bg6.png) repeat-x;
    background-size: auto 150%;
    background-position: bottom center;
    .content {
      display: flex;
      flex-direction: column;
      
      margin-left: $globalspacing-mb;
      margin-right: $globalspacing-mb;
      padding-top: 12vh;
      
      .textfont2 {
        display: none;
      }
      h4 {
        font-size: 9vw;
        margin-bottom: 3vh;
      }
      .content-text {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        img {
          width: auto;
          max-width: 100%;
          max-height: 78vh;
        }
      }
      
    }
  }

}