@import './../themes/variants';

.audit-page {
  .content {
    padding-left: $globalspacing;
    padding-right: $globalspacing;
  }
  h4 {
    text-align: left;
    padding-top: 21vh;
    
    // margin-right: $globalspacing;
  }
  .audit-content {
    display: flex;
    justify-content: space-between;
    padding-top: 13vh;
  }

  .audit-content-left {
    text-align: left;
   
    > div {
      display: flex;
      // font-size: 24vw*$designRate;
      align-items: center;
      a {
        // color: $color2;
        // font-size: 24vw*$designRate;
        // margin-left: 10vw*$designRate;
        // display: flex;
        // align-items: center;
        svg {
          margin-left: 4px;
        }
        // &:hover {
        //   text-decoration: underline;
        // }
      }
    }
    img {
      margin-right: 30vw*$designRate;
      margin-left: 30vw*$designRate;
      margin-top: 2vh;
      margin-bottom: 4vh;
      height: 52vw*$designRate;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .audit-content-right {
    img {
      width: 480vw*$designRate;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .audit-page {
    background: url(./../assets/mobile/bg4.jpg) no-repeat;
    background-size: cover;
    .content{
      padding-left: $globalspacing-mb;
      padding-right: $globalspacing-mb;
    }
    .audit-content {
      padding-top: 7vh;
    }
    h4 {
      font-size: 9vw;
    }
    h2 {
      margin-bottom: 3vh;
    }
    .audit-content-left {
      margin-bottom: 10vh;
      >div {
        img {
          height: 4.5vh;
        }
        a {
          font-size: 4vw;
        }
      }
    }
    .audit-content-right {
      img {
        width: 70%;
      }
      
    }
    .audit-content {
      flex-direction: column;
    }
  }
}