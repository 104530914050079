@import './../themes/variants';

// ecosystem-page
.ecosystem-page {
  // position: relative;
  // height: 180vh;
  .content {
    height: 100%;
    margin-left: $globalspacing;
    margin-right: $globalspacing;
    text-align: left;
    padding-top: 43vh;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
  }
  // img {
  //   position: absolute;
  //   top: -10%;
  //   width: 1522vw * $designRate;
  //   left: -32vw * $designRate;
  // }
}

@media only screen and (max-width: 1024px) {
  .ecosystem-page {
    background: url(./../assets/mobile/eco.jpg) no-repeat;
    background-size: cover;
    .content {
      margin-left: $globalspacing-mb;
      margin-right: $globalspacing-mb;
      padding-top: 12vh;
    }
    
  }
}